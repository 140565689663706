export const collectionstEndpoints = {
	homepage: "homepage.headers.cta,homepage.headers.image,homepage.headers.imageMobile,homepage.headers.title,homepage.headers.description,homepage.brandImages.name,homepage.brandImages.image,homepage.brandImages.link",
	// eslint-disable-next-line camelcase
	sticky_bar: "sticky_bar.button.label,sticky_bar.button.href,sticky_bar.text.content,sticky_bar.text.nodeType",

	collection_pages: "collection_pages,collection_pages.content,collection_pages.text,collection_pages.image,collection_pages.icon,collection_pages.icon.image,collection_pages.headerImage,collection_pages.headerImage.image,collection_pages.headerTitle,collection_pages.headerTagline,collection_pages.content,collection_pages.image.image,collection_pages.productListing",

	top_brands_per_categories: "top_brands_per_categories,top_brands_per_categories.brand_list",
	popups: "popups,popups.text,popups.image,popups.image_mbl",

	service_partners: "service_partners,service_partners.brands",
	stories: "stories,stories.thumbnail,stories.cta,stories.featuredBike,stories.pagesToShow",

	confirmation_pages: "confirmation_pages,confirmation_pages.cta,confirmation_pages.mobile_img,confirmation_pages.desktop_img,confirmation_pages.lead_magnets,confirmation_pages.lead_magnets.image,confirmation_pages.lead_magnets.btn_icon",

	mega_menus: "mega_menus,mega_menus.topCategories,mega_menus.topCategories.brands,mega_menus.topCategories.subCategories,mega_menus.simpleCategories,mega_menus.simpleBrands",
	premium_brand_widgets:	"premium_brand_widgets,premium_brand_widgets.staticTiles,premium_brand_widgets.staticTiles.imageDesktop,premium_brand_widgets.staticTiles.ctaBtnIcon,premium_brand_widgets.staticTiles.imageMobile,premium_brand_widgets.googleReviews,premium_brand_widgets.googleReviews.reviewer,premium_brand_widgets.liveNotifications,premium_brand_widgets.liveNotifications.image,premium_brand_widgets.liveNotifications.displayOnPages,premium_brand_widgets.recommendedBikeSlugs,premium_brand_widgets.activeUntil",
	brand_worlds: "brand_worlds,brand_worlds.heroSectionDescription,brand_worlds.buttonsBackgroundColor,brand_worlds.buttonsBackgroundColor,brand_world.heroSectionTitle,brand_world.brandSlug,brand_worlds.title,brand_worlds.description,brand_worlds.ctaBtnText,brand_worlds.brandLogo,brand_worlds.blurOnTop,brand_worlds.movingCircles,brand_worlds.brandCategories,brand_worlds.brandCategories.title,brand_worlds.brandCategories.subTitle,brand_worlds.brandCategories.categoryBtnCta,brand_worlds.brandCategories.categoryImage,brand_worlds.brandCategories.categorySlug,brand_worlds.withBrand,brand_worlds.withBrand.title,brand_worlds.withBrand.description,brand_worlds.withoutBrand,brand_worlds.withoutBrand.title,brand_worlds.withoutBrand.description,brand_worlds.factsSectionTitle,brand_worlds.factsSectionDescription,brand_worlds.facts,brand_worlds.facts.number,brand_worlds.facts.subTitle,brand_worlds.facts.description,brand_worlds.serviceCenter,brand_worlds.serviceCenter.description,brand_worlds.serviceCenter.link,brand_worlds.serviceCenter.title,brand_worlds.serviceCenter.serviceCentersMap,brand_worlds.brandLogoBlack,brand_worlds.leadMagnets,brand_worlds.leadMagnets.title,brand_worlds.leadMagnets.description,brand_worlds.leadMagnets.btn_href,brand_worlds.leadMagnets.btn_label,brand_worlds.leadMagnets.btn_icon,brand_worlds.leadMagnets.image,brand_worlds.insuranceTitle,brand_worlds.insuranceImage,brand_worlds.insuranceInfo,brand_worlds.insuranceInfo.header,brand_worlds.insuranceInfo.labelWithLogo,brand_worlds.insuranceInfo.labelWithLogo.label,brand_worlds.insuranceInfo.labelWithLogo.logo,brand_worlds.lifestyleVideo",
	organizations: "organizations,organizations.loginPopup,organizations.listingItem,organizations.banner,organizations.stickyBar,organizations.home"
}

export const strapiCollections = {
	homepage: "homepage",
	stickyBar: "sticky_bar",
	collectionPages: "collection_pages",
	popups: "popups",
	topBrandsPerCategory: "top_brands_per_categories",
	stories: "stories",
	servicePartners: "service_partners",
	confirmationPages: "confirmation_pages",
	megaMenus: "mega_menus",
	brandWorld: "brand_worlds",
	organizations: "organizations",
	premiumBrandWidgets: "premium_brand_widgets"
}

export const globalCollections = [
	strapiCollections.megaMenus,
	strapiCollections.stickyBar,
	strapiCollections.popups,
	strapiCollections.topBrandsPerCategory,
	strapiCollections.homepage,
	strapiCollections.collectionPages,
	strapiCollections.stories,
	strapiCollections.servicePartners,
	strapiCollections.confirmationPages,
	strapiCollections.brandWorld,
	strapiCollections.premiumBrandWidgets
]
