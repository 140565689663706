import React, {useContext, useState} from "react"
import {useTranslation} from "next-i18next"
import CheckoutContext from "../../../context/CheckoutContext"
import useDiscount from "../../../hooks/useDiscount"
import Typography from "../../reusable/Typography/Typography"
import {CheckoutBtn} from "./CheckoutBtn"
import {BikeType} from "../../../utility/Types"
import ZeroInterestTooltipSection from "./ZeroInterestTooltipSection"
import LayoutContext from "../../../context/LayoutContext"
import CartContext from "../../../context/CartContext"
import {formatPrice, isOccasionBike} from "../../../utility/Helper"
import getMainCategory from "../../filter/GetMainCategory"
import {categorySlugs} from "../../filter/config/Categories"
import Button from "../../reusable/components/Button"
import DurationAndDownpaymentContent from "./DurationAndDownpaymentContent"
import MobileModal from "../../reusable/MobileModal"
import {Check} from "react-bootstrap-icons"

type Props = {
	planLength: any
	initialPrice?: any
	customInstanceDiscount?: number
	product: BikeType
	isSecondHand: boolean
	isMobile: boolean
	disabled?: boolean
	isLoading?: boolean
	handleClick?: any
	isAccessory?: boolean
	handleSizeClick?: any
	priceInputBesidesTitle?: boolean
	hasPlaceHolder?: boolean
	hasOrganizationDiscount?: boolean
	secondHandPrice?: any
	selectedSize?: any
	selectedVariation?: any
	startShake?: () => void
	handleCheckoutBtnClick: (directPayment?: any) => void
	addingToCart?: boolean
    isFinancingPopupOpen?: boolean
	handleFinancigPopupClose?: (isFinancingPopupOpen?: boolean) => void
}

const DurationAndDownpayment: React.FC<Props> = ({
	handleFinancigPopupClose = () => {},
	customInstanceDiscount,
	handleCheckoutBtnClick,
	isFinancingPopupOpen,
	secondHandPrice,
	handleSizeClick,
	addingToCart,
	selectedSize,
	initialPrice,
	isSecondHand,
	startShake,
	planLength,
	isLoading,
	disabled,
	product
}) => {
	const [isPopupOpen, setIsPopupOpen] = useState(false)
	const {isMobile} = useContext(LayoutContext)
	const {items} = useContext(CartContext)
	const isCartCompatibility = items.length === 0 ?
		true : isSecondHand === (isSecondHand ? items?.filter(item => !isOccasionBike(item.product)).length < 1 :
			items?.filter(item => !isOccasionBike(item.product)).length < 1)
	const {
		finalPriceWithoutDownPayment,
		onSale,
		finalPriceWithDownPayment
	} = useDiscount(product)
	const {data: checkoutData} = useContext(CheckoutContext)
	const mainCategory = getMainCategory(product?.categories)
	const isAccessory = mainCategory?.name.toLowerCase() === categorySlugs.accessories
	const monthlyPriceWithoutDownpayment = Math.ceil(finalPriceWithoutDownPayment / planLength)
	const {t} = useTranslation()

	const handlePopupClose = () => {
		setIsPopupOpen(false)
		handleFinancigPopupClose?.()
	}

	return (<>
		<ZeroInterestTooltipSection hasTransparentBg={isMobile}
			className={isMobile ? "pb-1" : ""}/>
		<div style={{borderRadius: "16px", border: isMobile ? "1px solid transparent" : "1px solid #DEE2E6"}}>
			<div className="d-none d-md-block">
				<DurationAndDownpaymentContent
					handleCheckoutBtnClick={handleCheckoutBtnClick}
					product={product}
					isSecondHand={isSecondHand}
					disabled={!selectedSize}
					isLoading={addingToCart}
					handleClick={handleCheckoutBtnClick}
					isAccessory={isAccessory}
					handleSizeClick={startShake}
					planLength={planLength}
					initialPrice={initialPrice}
					priceInputBesidesTitle
					isMobile={isMobile}
					checkoutData={checkoutData}
					onSale={onSale}
					t={t}
					isCartCompatibility={isCartCompatibility}
					customInstanceDiscount={customInstanceDiscount}
					monthlyPriceWithoutDownpayment={monthlyPriceWithoutDownpayment}
					finalPriceWithDownPayment={finalPriceWithDownPayment}
					finalPriceWithoutDownPayment={finalPriceWithoutDownPayment}
				/>
			</div>
			<div className="duration-downpayment-mobile px-3 d-md-none">
				<div
					className="border rounded-3 bg-white d-flex align-items-start
                justify-content-between cursor-pointer"
					onClick={() => setIsPopupOpen(true)}
					style={{padding: "12px 16px"}}>
					<div>
						<Typography
							variant={"bodySmBold"}
							semanticTag="span"
							className={"text-capitalize"}>
				CHF
						</Typography>
						<Typography variant={"bodySmBold"}
							className="ms-1"
							semanticTag="span">
							{`${Math.ceil(finalPriceWithDownPayment / planLength)}`}
						</Typography>
						<Typography
							variant={"bodySmBold"}
							semanticTag="span"
							className="ms-1">
				/m, {planLength} {t("Monate")}
						</Typography>
						<Typography style={{fontWeight: 400}}
							variant={"bodySm"}
							semanticTag="h3"
							className="mb-0">
							{t("down_payment")}: {finalPriceWithoutDownPayment - finalPriceWithDownPayment}
						</Typography>
					</div>

					<Typography style={{color: "#956306"}}
						variant={"bodySmBold"}
						semanticTag="span">{t("change")}
					</Typography>
				</div>
				<MobileModal handleClose={handlePopupClose}
					modalOpen={isPopupOpen || isFinancingPopupOpen}
					modalTitle={t("no-interest-label")}>
					<DurationAndDownpaymentContent
						handleCheckoutBtnClick={handleCheckoutBtnClick}
						product={product}
						isSecondHand={isSecondHand}
						disabled={!selectedSize}
						isLoading={addingToCart}
						handleClick={handleCheckoutBtnClick}
						isAccessory={isAccessory}
						handleSizeClick={startShake}
						planLength={planLength}
						initialPrice={initialPrice}
						priceInputBesidesTitle
						isMobile={isMobile}
						checkoutData={checkoutData}
						onSale={onSale}
						t={t}
						isCartCompatibility={isCartCompatibility}
						monthlyPriceWithoutDownpayment={monthlyPriceWithoutDownpayment}
						finalPriceWithDownPayment={finalPriceWithDownPayment}
						finalPriceWithoutDownPayment={finalPriceWithoutDownPayment}
						isPopup
						setIsPopupOpen={handlePopupClose}
					/>
				</MobileModal>
			</div>

			<div className="mt-4 mt-md-0 px-3 px-md-3 pb-4 pb-md-3">
				{
					<div style={{flex: 1}}>
						<CheckoutBtn
							planLength={planLength}
							product={product}
							isSecondHand={isSecondHand}
							secondHandPrice={secondHandPrice}
							handleSizeClick={startShake}
							disabled={!selectedSize}
							isLoading={addingToCart}
							handleClick={handleCheckoutBtnClick}
							stockStatus={product.stock_status}
						/>
					</div>
				}
				<div style={{gap: "8px"}}
					className="text-center col-12 mt-1 d-flex align-items-center justify-content-center mb-2 mb-md-3 pb-3 border-md-bottom">
					<Check color="#2CA58D"
						size={26} />
					<Typography variant="bodySm"
						className="text-secondary">
						{t("best-monthly-price-label")}
					</Typography>
				</div>
				<Button bgColor="#FFE9C2"
					textColor="#212529"
					color="#212529"
					paddingY="0"
					className="w-100 py-2 data-cypress-checkout-btn"
					spinnerColor="#212529"
					label={t("pay-one-time")}
					high
					disabled={!selectedSize}
					onClick={() => handleCheckoutBtnClick(true)}
					style={{height: 48}}
					isNoUppercase
				>
					<div className="d-flex gap-2 justify-content-center align-items-center">
						<Typography style={{lineHeight: "20px", fontWeight: 800}}
							variant="bodySmBold">
							{`${t("pay-full-price-chf")} ${formatPrice(finalPriceWithoutDownPayment)}`}
						</Typography>

					</div>
				</Button>
			</div>
		</div>
	</>
	)
}

export default DurationAndDownpayment

