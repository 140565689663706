import {useRouter} from "next/router"
import {findBikeAttributeBySlug, isCategory} from "../utility/Helper"
import {useContext} from "react"
import OrganizationContext from "../context/OrganizationContext"

function useBadges(bike) {
	const {organizationSlug} = useContext(OrganizationContext)

	const router = useRouter()
	const {locale} = router
	const isNew = isCategory(bike, "new")
	const bioBikeCatName = locale === "de" ? "nicht-elektrische-bikes" : "velos-non-electriques"
	const isNonEBike = isCategory(bike, bioBikeCatName)
	const isFeatured = isCategory(bike, organizationSlug ? `recommended-on-${organizationSlug}` : "recommended-on-plp")
	const hasTestDrive = findBikeAttributeBySlug(bike, "test-drive")
	const isRentable = findBikeAttributeBySlug(bike, "bike-rental")
	const saleOfTheWeek = isCategory(bike, "sale-of-the-week")
	const is2025 = isCategory(bike, "is_2025")
	const isBikeOfTheMonth = isCategory(bike, "e-bike-of-the-month")
	const isBrandWorldRecommended = isCategory(bike, "recommended")
	return {isNew, isNonEBike, isFeatured, saleOfTheWeek, is2025, isBikeOfTheMonth, hasTestDrive, isRentable, isBrandWorldRecommended}
}

export default useBadges
