import Typography from "../../reusable/Typography/Typography"
import React, {useContext} from "react"
import {useTranslation} from "next-i18next"
import Image from "next/image"
import OrganizationContext from "../../../context/OrganizationContext"
import useDiscount from "../../../hooks/useDiscount"
import {formatPrice} from "../../../utility/Helper"
import DataContext from "../../../context/DataContext"
import {getOrganizationDataFromStrapi} from "../../../utility/strapi/helpers"

type Props = {
	product: any
	isSecondHand: boolean
	disabled?: boolean
	isLoading?: boolean
	handleClick?: any
	isAccessory?: boolean
	handleSizeClick?: any
	planLength?: any
	initialPrice?: any
	customInstanceDiscount?: number
	isOnSale?: boolean
	hideTooltip?: boolean
}

const PriceTooltipWithCheckoutBtn: React.FC<Props> = ({
	product,
	isSecondHand,
	customInstanceDiscount,
	initialPrice,
	disabled,
	planLength,
	isLoading,
	handleClick,
	handleSizeClick,
	isOnSale,
	hideTooltip = false
}) => {
	const {organizationSlug} = useContext(OrganizationContext)
	const {pageProps} = useContext(DataContext)
	const organizations = pageProps?.strapiContent?.data?.organizations

	const organizationData = getOrganizationDataFromStrapi(organizations, organizationSlug)
	const {t} = useTranslation()
	const {finalPriceWithDownPayment, finalPriceWithoutDownPayment} = useDiscount(product)
	const isCustomInstance = process.env.NEXT_PUBLIC_CUSTOM_INSTANCE
	const displayDiscountPrice = customInstanceDiscount ? customInstanceDiscount : product.discount
	const showPartnershipExclusiveText = Boolean(product?.customInstanceDiscounts?.[organizationSlug])

	return (
		<div className="col-12 mt-4 pt-1">
			{/* Price Tooltip For Discount */}
			{((isCustomInstance && customInstanceDiscount) || isOnSale) && !hideTooltip && <div className="col-12 text-white downpayment-price-tooltip position-relative d-flex gap-2 align-items-center justify-content-between">
				<div className="d-flex align-items-center">
					{isCustomInstance && showPartnershipExclusiveText &&
							<>
								<div className="d-none d-md-flex align-items-center">
									<Image src={isCustomInstance ? organizationData?.companyIcon : null}
										className="d-none d-md-block"
										height={24}
										width={40}/>
								</div>
								<div className="d-flex d-md-none align-items-center">
									<Image
										src={isCustomInstance ? organizationData?.companyIcon : null}
										className="d-block d-md-none"
										height={12}
										width={20}/>
								</div>
							</>
					}
					<Typography variant="bodySmBold"
						className={`${isCustomInstance ? "ms-2 " : ""} d-none d-md-block`}
						semanticTag="span"
						style={{lineHeight: "normal"}}>{(isCustomInstance && customInstanceDiscount) ? organizationData?.companyName : null} {t("exclusive-discount")} </Typography>
					<Typography variant="bodyXSmBold"
						className={`${isCustomInstance ? "ms-2 " : ""} d-md-none`}
						semanticTag="span"
						style={{lineHeight: "normal"}}>{(isCustomInstance && customInstanceDiscount) ? organizationData?.companyName : null} {t("exclusive-discount")}</Typography>
				</div>
				<Typography variant="bodySmBold"
					semanticTag="span"
					className="ms-2 d-none d-md-block"
					style={{lineHeight: "normal"}}>-{displayDiscountPrice} CHF</Typography>
				<Typography variant="bodyXSmBold"
					semanticTag="span"
					className="ms-2 d-md-none"
					style={{lineHeight: "normal"}}>-{displayDiscountPrice} CHF</Typography>
			</div>}
			{/* Checkout Data & Button */}
			<div className="d-flex align-items-center justify-content-between"
				style={{marginTop: 12}}>
				<div className="col-auto position-relative">
					<div className="col-auto"
						style={{color: isOnSale || customInstanceDiscount ? "#E61C45" : ""}}>
						<Typography variant={"bodySmBold"}
							semanticTag="span">CHF</Typography>
						<Typography variant={"heading1Black"}
							className="ms-1"
							semanticTag="span"
							style={{fontSize: 36, lineHeight: 1.3}}>
							{`${Math.ceil(finalPriceWithDownPayment / (planLength))}`}
						</Typography>
						<Typography variant={"bodySmBold"}
							semanticTag="span"
							className="ms-1">
							{`${t("pro Monat")}`}
						</Typography>
						{!isSecondHand && (
							<div style={{color: "#6C7074"}}>
								<Typography variant="bodySm"
									semanticTag="span"
									className={"text-start col-12 text-capitalize"}
									style={{color: isOnSale || customInstanceDiscount ? "#E61C45" : ""}}>
									{`${t("Gesamtpreis")}: `}
								</Typography>
								<Typography variant={"bodySm"}
									semanticTag="span"
									style={{color: isOnSale || customInstanceDiscount ? "#E61C45" : ""}}>
									{formatPrice(finalPriceWithoutDownPayment)}
								</Typography>
								{(isOnSale || customInstanceDiscount) &&
									<Typography variant={"bodySm"}
										semanticTag="span"
										className="ms-1 text-decoration-line-through">
										{formatPrice(initialPrice)}
									</Typography>}
								<Typography variant={"bodySm"}
									semanticTag="span"
									className="ms-1">
									(UVP)
								</Typography>
								<Typography variant={"bodyXSm"}
									semanticTag="div">
									Inklusive MwSt.
								</Typography>
							</div>)
						}
					</div>
				</div>
			</div>
		</div>
	)
}

export default PriceTooltipWithCheckoutBtn
