import Image from "next/image"
import Typography from "../../reusable/Typography/Typography"
import Button from "../../reusable/components/Button"
import React, {useContext} from "react"
import {useTranslation} from "next-i18next"
import LayoutContext from "../../../context/LayoutContext"
import {useRouter} from "next/router"
import AnalyticsContext from "../../../context/AnalyticsContext"
import {gaCategories, gaEvents} from "../../../config/googleAnalytics/events"
import {StaticTile} from "../../../utility/Types"
import {formatImageSrc} from "../../../strapi/strapiHelpers"

const GetCustomOfferTile = ({isIkeaDesign = false, staticTileContent = null}: { isIkeaDesign?: boolean, staticTileContent?:StaticTile}) => {
	const {t} = useTranslation()
	const {isListView, isMobile} = useContext(LayoutContext)
	const router = useRouter()

	const bgImageStyles = isListView ? {} : {
		backgroundImage: `url(${staticTileContent && staticTileContent?.imageDesktop?.data?.attributes?.url ? formatImageSrc(staticTileContent?.imageDesktop?.data?.attributes?.url) : "/assets/images/credit-check.jpg"})`,
		backgroundPosition: "center",
		backgroundSize: "cover",
		padding: "48px 72px",
		outline: "1px solid #DEE2E6"
	}
	const {reactGA} = useContext(AnalyticsContext)

	return (
		<div className="credit-check-tile h-100">
			<div
				className={`h-100 d-none d-md-flex info-card ${isListView ? "container col-11 mx-auto d-md-flex gap-5 flex-row-reverse justify-content-between py-4" : "p-3 flex-column justify-content-end align-items-end"}`}
				style={{minHeight: isListView ? "250px" : "400px", maxHeight: "432px", borderRadius: isIkeaDesign ? "0px" : "24px", minWidth: isMobile ? "164px" : "260px", ...bgImageStyles}}>
				{isListView ?
					<div className="position-relative col-5">
						<Image src={staticTileContent && staticTileContent?.imageDesktop?.data?.attributes?.url ? formatImageSrc(staticTileContent?.imageDesktop?.data?.attributes?.url) : "/assets/images/credit-check.jpg"}
							layout={"fill"}
							objectFit={"cover"}
							style={{borderRadius: 16}}/>
					</div>					:
					null
				}
				<div className={`info-card-text  mt-4 ${isListView ? "d-flex flex-column col-5 justify-content-center mt-md-0" : ""}`}>
					<Typography variant={isListView ? "heading2Black" : "heading3MdBodyLgBlack"}
						semanticTag="h4"
						className="mb-2"
						style={{fontSize: isListView ? 32 : "", lineHeight: isListView ? "" : "26px", color: isIkeaDesign ? "#fff" : "#000"}}>{staticTileContent ? staticTileContent?.title : t("creditcheck-eligible-for-financing-title")}</Typography>
					<Typography variant={"bodySm"}
						style={{color: isIkeaDesign ? "#fff" : "#000"}}
						semanticTag="span">{staticTileContent ? staticTileContent?.description : t("eligible-for-financing-desc")}</Typography>

					<div style={{marginTop: "2rem"}}
						className="col-12">
						<Button
							href={staticTileContent ? staticTileContent.ctaLink : router?.locale === "de" ? "/creditcheck/2" : "/fr/creditcheck/2"}
							fontSizeSmall
							bgColor={staticTileContent ? staticTileContent?.ctaBackgroundColor : "#FEC04D"}
							textColor={staticTileContent ? staticTileContent?.ctaTextColor : "#212529"}
							onClick={() => {
								reactGA?.event({
									category: gaCategories.plp,
									action: gaEvents.creditCheckClickedOnPLP,
									label: `${gaEvents.creditCheckClickedOnPLP}`,
									nonInteraction: false
								})
							}}
							color={staticTileContent ? staticTileContent?.ctaTextColor : "#212529"}
							className={isListView ? "mt-2" : "w-100"}>
							{staticTileContent ? <>{staticTileContent?.ctaBtnIcon?.data?.attributes?.url ? <Image src={ formatImageSrc(staticTileContent.ctaBtnIcon?.data?.attributes?.url)}
								width={20}
								height={20}
								objectFit="contain"/> : null}<Typography semanticTag="span"
								variant={"bodySmBlack"}
								className="ms-2"
								style={{padding: "2px 0"}}>{staticTileContent.ctaText}</Typography></> : <><Image src={"/assets/icons/credit-check-icon.svg"}
								width={20}
								height={20}
								objectFit="contain"/>
							<Typography semanticTag="span"
								variant={"bodySmBlack"}
								className="ms-2"
								style={{padding: "2px 0"}}>{t("check-score-btn")}</Typography></>}
						</Button>
					</div>
				</div>
			</div>
			<div
				className="border col-12 info-card d-md-none "
				style={{borderRadius: "24px"}}>
				<div className="col-12 info-card-image position-relative">
					<Image src={staticTileContent && staticTileContent?.imageMobile?.data?.attributes?.url ? formatImageSrc(staticTileContent?.imageMobile?.data?.attributes?.url) : "/assets/images/check-credit-score-img.png"}
						layout={"fill"}
						objectFit={"cover"}
						objectPosition="top"/>
				</div>
				<Typography variant={"heading3MdBodyLgBlack"}
					semanticTag="h4"
					className="my-3">{staticTileContent ? staticTileContent?.title : t("creditcheck-eligible-for-financing-title")}</Typography>

				<div className="info-card-text d-md-flex gap-5 justify-content-between align-items-center">
					<Typography variant={"bodyLg"}
						semanticTag="span">{staticTileContent ? staticTileContent?.description : t("eligible-for-financing-desc")}</Typography>
					<div className="col-12 mt-3 ">
						<Button
							href={router?.locale === "de" ? "/creditcheck/2" : "/fr/creditcheck/2"}
							fontSizeSmall
							bgColor={staticTileContent ? staticTileContent?.ctaBackgroundColor : "#FEC04D"}
							textColor={staticTileContent ? staticTileContent?.ctaTextColor : "#212529"}
							color={staticTileContent ? staticTileContent?.ctaTextColor : "#212529"}
							onClick={() => {
								reactGA?.event({
									category: gaCategories.plp,
									action: gaEvents.creditCheckClickedOnPLP,
									label: `${gaEvents.creditCheckClickedOnPLP}`,
									nonInteraction: false
								})
							}}
							className="w-100">
							{staticTileContent ? <>{staticTileContent?.ctaBtnIcon?.data?.attributes?.url ? <Image src={ formatImageSrc(staticTileContent.ctaBtnIcon?.data?.attributes?.url)}
								width={20}
								height={20}
								objectFit="contain"/> : null}<Typography semanticTag="span"
								variant={"bodySmBlack"}
								className="ms-2"
								style={{padding: "2px 0"}}>{staticTileContent.ctaText}</Typography></> : <><Image src={"/assets/icons/credit-check-icon.svg"}
								width={20}
								height={20}
								objectFit="contain"/>
							<Typography semanticTag="span"
								variant={"bodySmBlack"}
								className="ms-2"
								style={{padding: "2px 0"}}>{t("check-score-btn")}</Typography></>}

						</Button>
					</div>
				</div>
			</div>
		</div>
	)
}

export default GetCustomOfferTile
