import React, {useContext, useState} from "react"
import {useTranslation} from "next-i18next"
import Typography from "../../reusable/Typography/Typography"
import {MenuTitle} from "../NavBar"
import Link from "next/link"
import AnalyticsContext from "../../../context/AnalyticsContext"
import {gaCategories, gaEvents} from "../../../config/googleAnalytics/events"
import {useRouter} from "next/router"
import Image from "next/image"
import BookConsultation from "../../bikeDetails/BookConsultation"
import EbikesMenu from "./EbikesMenu"
import InfocenterMenu from "./InfocenterMenu"
import MobileEcosystemTabs from "./MobileEcosystemTabs"
import {filterUrl} from "../../filter/FilterUrl"
import urlParams from "../../../utility/UrlParams"
import FreeServicesMenu from "./FreeServicesMenu"
import {NewMobileMegaMenuSlugsList} from "../../../constants/TopCategories"
import Search from "../../reusable/Search"
import DataContext from "../../../context/DataContext"
import ListQuantityIndicator from "../../reusable/ListQuantityIndicator"
import {X} from "react-bootstrap-icons"

type Props = {
	secondLineMenu: MenuTitle[]
	onMegaMenuBtnClick?: (router, pathname) => void
	showSearch:boolean
}

const NewMobileMegaMenu = ({secondLineMenu, onMegaMenuBtnClick, showSearch}: Props): React.ReactElement => {
	const {t} = useTranslation(["common"])
	const {reactGA} = useContext(AnalyticsContext)
	const {comparisonProducts} = useContext(DataContext)
	const router = useRouter()
	const {locale} = router
	const [isEbikesMenuVisible, setIsEbikesMenuVisible] = useState(false)
	const [isInfocenterMenuVisible, setIsInfocenterMenuVisible] = useState(false)
	const [isFreeServiceMenuOpen, setIsFreeServicesMenuOpen] = useState<boolean>(false)

	const [activeCategory, setActiveCategory] = useState(null)
	const saleTitleSelection = "Sale"

	const handleMenuItemClick = item => {
		setActiveCategory(item)
		setIsEbikesMenuVisible(prev => !prev)

		if (item.slugName === "e-bikes") {
			reactGA?.event({
				category: gaCategories.megaMenu,
				action: gaEvents.allEBikesButton,
				label: gaEvents.allEBikesButton,
				nonInteraction: false
			})
		}
	}

	return (
		<div className={"w-100 vh-100 bg-white pt-2 w-100 overflow-auto"}
			onClick={e => {
				e.stopPropagation()
			}}>
			<nav style={{
				paddingBottom: 35
			}}
			className={"overflow-auto"}>
				<div className="container my-2">
					<X size={24}
						onClick={() => onMegaMenuBtnClick(router, router.pathname)} /></div>
				{showSearch ? <Search hideAi
					isMegaMenu /> : null}
				{(activeCategory?.slugName !== "45-km" && activeCategory?.slugName !== "sale") &&
				<EbikesMenu isEbikesMenuVisible={isEbikesMenuVisible}
					setIsEbikesMenuVisible={setIsEbikesMenuVisible}
					setIsMenuVisible={onMegaMenuBtnClick}
					activeCategory={activeCategory}
				/>}
				<InfocenterMenu setIsInfocenterMenuVisible={setIsInfocenterMenuVisible}
					isInfocenterMenuVisible={isInfocenterMenuVisible}
					menuItems={secondLineMenu[0].items}/>
				<FreeServicesMenu setIsFreeServicesMenuOpen={setIsFreeServicesMenuOpen}
					isFreeServicesMenuOpen={isFreeServiceMenuOpen} />
				<div className="d-flex flex-column pt-2 mt-1 px-2">
					<div className={`border-bottom d-flex pb-4 ${router.locale === "fr" ?
						"flex-row-reverse justify-content-end" : "justify-content-start"} gap-2`} >
						<Typography
							className={` ms-3 rounded-2 ${router.locale === "de" ? "" : "text-primary bold undeline"}`}
							onClick={() => {
								if (router.locale === "fr") {
									router.push(router.asPath, null, {locale: "de"})
								}
							}}
							variant="bodySmBold"
							semanticTag="span">{"DE"}</Typography>
						<Typography className={` ms-3 rounded-2 ${router.locale === "fr" ? "" : "text-primary bold"}`}
							onClick={() => {
								if (router.locale === "de") {
									router.push(router.asPath, null, {locale: "fr"})
								}
							}}
							variant="bodySmBold"
							semanticTag="span" >{"FR"}</Typography>
					</div>
					<div className="border-bottom pb-4">
						{NewMobileMegaMenuSlugsList[locale].map((item, i) =>
							item.slugName === "45-km" || item.slugName === "sale" ?
								<Link href={item.slugName === "45-km" ?
									filterUrl({[urlParams.categories]: "45-km"}, null, null) :
									"/alle-modelle-e-bike-schweiz?categories=sale_&sortOrder=sale"}
								key={item.slugName}>
									<div
										className={"d-flex gap-2 align-items-center px-2 cursor-pointer height-link rounded-2"}>
										<div className="d-flex align-items-center py-2">
											<Typography variant="bodyLgBold"
												semanticTag="span"
												style={{lineHeight: "normal"}}>
												{item.slugName === "sale" ? t(saleTitleSelection) : t(item.title)}
											</Typography>
										</div>
										{item.slugName === "sale" && <Image src="/assets/icons/fire.svg"
											height={20}
											width={20}/>}
									</div>
								</Link> :
								<div key={i}
									className="d-flex justify-content-between align-items-center
                                    px-2 cursor-pointer height-link rounded-2"
									onClick={() => handleMenuItemClick(item)}
									style={{backgroundColor: router.query.category &&
                                    router.query.category?.includes(item.link) && "#eff1f3"}}>
									<div className="d-flex align-items-center py-2">
										<Typography variant="bodyLgBold"
											semanticTag="span"
											style={{color: item.slugName === "on-sale" && "#E61C45"}}>
											{t(item.title)}
										</Typography>
									</div>
									<Image	src={"/assets/icons/chevron-right.svg"}
										width={20}
										height={20}
										alt="chevron right"/>
								</div>
						)}
					</div>
				</div>

				<div className="links-section pt-4 px-3">
					<div className="d-flex justify-content-between align-items-center py-1">
						<div className="d-flex align-items-center py-2">
							<Image	src={"/assets/icons/new-to-ebikes.svg"}
								width={20}
								height={20}
								alt="new to ebikes"/>
							<Typography variant="bodyLg"
								semanticTag="span"
								className="ms-3">{t("mega-menu-title-text-1")}</Typography>
						</div>
						<a
							href={"/suggestion/1"}
							rel="noreferrer"
							target="_blank"
							className="text-decoration-none px-2 py-1 rounded col-auto"
							style={{backgroundColor: "#EBEBEB", color: "#212529"}}
							onClick={() => {
								reactGA?.event({
									category: gaCategories.megaMenu,
									action: gaEvents.startQuiz,
									label: gaEvents.startQuiz,
									nonInteraction: false
								})
								onMegaMenuBtnClick(router, router.pathname)
							}}
						>
							<Typography variant="bodyXSmBold"
								semanticTag="span">{t("start-quiz")}</Typography>
						</a>
					</div>
					<div className="d-flex justify-content-between align-items-center py-1">
						<div className="d-flex align-items-center py-2">
							<Image	src={"/assets/icons/whats-app-button-black-icon.svg"}
								width={20}
								height={20}
								alt="electric"/>
							<Typography variant="bodyLg"
								semanticTag="span"
								className="ms-3">{t("We in WhatsApp")}</Typography>
						</div>
						<a href={"https://api.whatsapp.com/send?phone=41435051318"}
							rel="noreferrer"
							target="_blank"
							className="text-decoration-none px-2 py-1 rounded"
							style={{backgroundColor: "#EBEBEB", color: "#212529"}}>
							<Typography variant="bodyXSmBold"
								semanticTag="span">{t("text-us")}</Typography>
						</a>
					</div>
					<div className="border-bottom pb-4">
						<BookConsultation className="mt-3 ps-0"/>
					</div>
				</div>

				<div className="d-flex flex-column pt-2 mt-1 pb-4 px-3">
					<Link href={t("/comparison-page")}>
						<div className="d-flex justify-content-between align-items-center cursor-pointer">
							<div className="d-flex align-items-center py-2 cursor-pointer">
								<Image	src={"/assets/icons/compare-bikes-icon.svg"}
									width={20}
									height={20}
									alt="comparison"/>
								<Typography variant="bodyLg"
									semanticTag="span"
									className="ms-3">{t("comparison-list")}</Typography>
							</div>
							<div className="d-flex align-items-center gap-1">
								<ListQuantityIndicator listLength={comparisonProducts.length}
									isAbsolute={false}/>
								<Image	src={"/assets/icons/chevron-right.svg"}
									width={20}
									height={20}
									alt="chevron right"/>
							</div>
						</div>
					</Link>
					<div onClick={() => setIsFreeServicesMenuOpen(() => true)}
						className="d-flex justify-content-between align-items-center cursor-pointer">
						<div className="d-flex justify-content-start py-2 align-items-center">
							<Image	src={"/assets/icons/exclamation-mark.svg"}
								width={20}
								height={20}
								alt="Resources"/>
							<Typography variant="bodyLg"
								semanticTag="span"
								className="ms-3">{t("free-services")}</Typography>
						</div>
						<Image	src={"/assets/icons/chevron-right.svg"}
							width={20}
							height={20}
							alt="chevron right"/>
					</div>
					<div className="d-flex justify-content-between align-items-center">
						<div className="d-flex align-items-center py-2"
							onClick={() => setIsInfocenterMenuVisible(true)}>
							<Image	src={"/assets/icons/question.svg"}
								width={20}
								height={20}
								alt="Resources"/>
							<Typography variant="bodyLg"
								semanticTag="span"
								className="ms-3">{t("Resources")}</Typography>
						</div>
						<Image	src={"/assets/icons/chevron-right.svg"}
							width={20}
							height={20}
							alt="chevron right"/>
					</div>
					<Link href={t("/kundendienst")}>
						<div className="d-flex align-items-center py-2 cursor-pointer">
							<Image	src={"/assets/icons/support.svg"}
								width={20}
								height={20}
								alt="support"/>
							<Typography variant="bodyLg"
								semanticTag="span"
								className="ms-3">{t("Kundendienst")}</Typography>
						</div>
					</Link>
					<Link href={t("/faq/konzept")}>
						<div className="d-flex align-items-center py-2 cursor-pointer">
							<Image	src={"/assets/icons/lights.svg"}
								width={20}
								height={20}
								alt="concept"/>
							<Typography variant="bodyLg"
								semanticTag="span"
								className="ms-3">{t("concept")}</Typography>
						</div>
					</Link>
					<MobileEcosystemTabs />
				</div>
			</nav>
		</div>
	)
}

export default NewMobileMegaMenu

NewMobileMegaMenu.defaultProps = {
	secondLineMenu: [
		{
			name: "Resources", items: [
				{name: "Blog", href: "/blog"},
				{name: "Referral", href: "/referral"},
				{name: "concept", href: "/konzept"},
				{name: "FAQ", href: `/faq${("/konzept")}`},
				{name: "Über uns", href: "/ueber-uns"},
				{itemLabel: "E-Bike Wiki", href: "/bike-verzeichnis"},
				{name: "Partnership", href: "/partnerprogramm"}
			]
		}
	]
}
