import {gaCategories, gaEvents} from "../config/googleAnalytics/events"
import {AttributesToShowType, fieldsToShowFromPostgresFetchType} from "../utility/Types"

export * from "./BrandAndCollectionNames"

export const allLocales = ["de", "fr", "en"]

export const sponsorshipBlogLinks = {
	de: "/blog/neu-lass-dir-dein-e-bike-sponsoren",
	fr: "/blog/fais-financer-ton-velo-electrique-par-dautres"
}

export const freeServices = {
	label: t => t("free-services"),
	isFreeServices: true,
	items: [{
		title: t => t("custom-offer-card-title"),
		description: t => t("take-short-quiz"),
		imageUrl: "/assets/icons/bike-question.svg",
		handleClick: paramsObject => {
			const {router} = paramsObject
			return router?.locale === "de" ? router.replace("/suggestion/1") : router.replace("/fr/suggestion/1")
		}
	},
	{
		title: t => t("get-contacted-card-title"),
		description: t => t("talk-to-our-experts-card-desc"),
		imageUrl: "/assets/icons/phone-icon-dark.svg",
		handleClick: paramsObject => {
			const {reactGA, openModal, bookAppointmentModalSetter} = paramsObject
			reactGA?.event({
				category: gaCategories.consultation,
				action: gaEvents.freeServicesDropdown,
				label: `${gaCategories.consultation} - ${gaEvents.freeServicesDropdown}`,
				nonInteraction: false
			})

			bookAppointmentModalSetter()
			openModal()
		}
	},
	{
		title: t => t("creditcheck-eligible-for-financing-title"),
		description: t => t("eligible-for-financing-desc"),
		imageUrl: "/assets/icons/credit-check-icon.svg",
		handleClick: paramsObject => {
			const {router} = paramsObject
			return router?.locale === "de" ? router.replace("/creditcheck/2") : router.replace("/fr/creditcheck/2")
		}
	},
	{
		title: t => t("sponsorship-title"),
		description: t => t("sponsorship-modal-description"),
		imageUrl: "/assets/icons/handshake.svg",
		handleClick: paramsObject => {
			const {router} = paramsObject
			return router.replace(sponsorshipBlogLinks[router?.locale || "de"])
		}
	},
	{
		title: t => t("ask-ai"),
		description: t => t("sponsorship-modal-description"),
		imageUrl: "/assets/images/ryder-logo-primary.svg",
		handleClick: () => null
	}]
}

export const emptySearchLabels = [["no-search-results-found", "find-it-another-way-label", "check-spelling-label", "enter-brand-name-label"], ["contact-us-for-help-label", "counselling", "book-consultation", "email-us-label"], ["tell-us-what-you-looking-for-label"]]
export const DEFAULT_FIELDS_TO_SHOW_IN_FILTERS:fieldsToShowFromPostgresFetchType = ["categories", "upsell_ids", "cross_sell_ids", "images", "meta_data", "attributes", "variations", "variation_objects", "cross_sell_products", "static_variations"]
export const defaultParams = {type: "-", categories: "-", frameVariants: "-", batterieSize: "-", maxGewicht: "-", attachments: "-", speed: "-", brand: "-", motorBrand: "-", filtersFarbe: "-", bikerHeight: "-", priceRange: "-", availability: "-", searchValue: "-", sortOrder: "-"}
export const DEFAULT_ATTRIBUTES_TO_SHOW_IN_FILTERS: AttributesToShowType = [
	"accessory-type",
	"attachments",
	"Akku",
	"battery-size",
	"brand",
	"cassette",
	"chain",
	"filters-color",
	"range",
	"fabric",
	"Filter Farbe",
	"frame",
	"frame-variant",
	"test-drive",
	"bike-rental",
	"hex-color",
	"back-wheel",
	"max-weight",
	"motor",
	"motor-brand",
	"motor-strength",
	"pedals",
	"seat-tube-length",
	"sizes",
	"rider-height",
	"speed",
	"trailer-compatibility",
	"weight",
	"wheel-size",
	"width",
	"youtube-link",
	"seat-tube-length",
	"back-wheel",
	"brake-disks",
	"brakes",
	"brakes-front",
	"brakes-rear",
	"cable-length",
	"cable-properties",
	"cover",
	"crank-handle",
	"damper",
	"depth",
	"dimensions",
	"dimming",
	"display",
	"feet-support",
	"fork",
	"front-wheel",
	"gear-change",
	"gears",
	"gearshift",
	"grips",
	"handlebar",
	"headlight",
	"heart-rate-measurement",
	"height",
	"hub",
	"leg-color",
	"length",
	"luggage-rack",
	"main-material",
	"materials",
	"max-load-capacity",
	"motor",
	"mounting",
	"mounting-type",
	"pedal-type",
	"pressure",
	"rack",
	"rims",
	"saddle",
	"saddle-post",
	"seat-depth",
	"seat-height",
	"seat-width",
	"stats",
	"stem",
	"suspension-travel",
	"tires",
	"trailer-compatibility"
]

export const filterButtons = [
	{filter: "speed", label: "same-speed", iconSrc: "/assets/icons/speed.svg"},
	{filter: "range", label: "same-max-range", iconSrc: "/assets/icons/max-range.svg"},
	{filter: "battery-capacity", label: "same-battery-capacity", iconSrc: "/assets/icons/battery-capacity.svg"}
]

export const premiumBrands = ["allegro", "cresta", "cilo"]

export 	const onlyAllowedLinksForAi = ["https://www.mybikeplan.ch/suggestion/1", "https://www.mybikeplan.ch/creditcheck/2", "https://www.mybikeplan.ch/blog/neu-lass-dir-dein-e-bike-sponsoren", "https://www.mybikeplan.ch/service-centers"]
